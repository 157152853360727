import React from 'react'

import { withMask } from 'use-mask-input'

import { Input } from '@chakra-ui/react'

import { MaskedInputProps } from './types'

export const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  ({ mask, ...props }) => {
    return <Input ref={withMask(mask)} {...props} />
  }
)

MaskedInput.displayName = 'MaskedInput'
